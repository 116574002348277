import React from "react";
import { Box, Typography, Divider, Container } from "@material-ui/core";

const Footer = () => {
  return (
    <Box mb={2}>
      <Container maxWidth="fixed">
        <Divider className="borderBox" />
        <Box className="displayCenter">
          <Typography
            variant="body2"
            style={{ color: "rgba(255, 255, 255, 0.6)" }}
          >
            Copyright©2024. Created with love by{" "}
            <span className="gradientfirexText" style={{ fontSize: "18px" }}>
              FIEREX
            </span>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
