export const NetworkContextName = "Fiers chain";
export const ACTIVE_NETWORK = 1001;
export const RPC_URL = "https://rpc1.fieroscan.com/";

/////master///////

// export const characterGenerativeNFTContract =
//   "0x6f16BfF4A9407BD1CAc3210eb65d20A9BF0F7820";
// export const carGenerativeNFTContract =
//   "0x082147aa4cb8F0ee35aC16e12dff04d35580CE75";
// export const bikeGenerativeNFTContract =
//   "0x3248b2961880256345E0b3363399AD5f867ddDEc";
// export const hoverBoardGenerativeNFTContract =
//   "0xa4E03f06f02Cde549216F9555d92E178974009d7";

/////master///////

////Staging///

export const characterGenerativeNFTContract =
  "0xf9500FE51890b6FF76Bc7f0d9247B5C89935a6b7";
export const carGenerativeNFTContract =
  "0x8D38a1157ff56802414763FDDE284Dee7310Ed78";
export const bikeGenerativeNFTContract =
  "0xD4ACde7b5D6ffba591Cbcb1e29184fAa674313bf";
export const hoverBoardGenerativeNFTContract =
  "0x2034E9A7f353BAbFB936C404AA8b52427833F172";

export const currencyUnit = "Fiero";

export const NetworkDetails = [
  {
    chainId: "0x3E9",
    chainName: "Fiers Mainnet",
    nativeCurrency: {
      name: "FIERO",
      symbol: "FIERO",
      decimals: 18,
    },
    rpcUrls: [RPC_URL],
    blockExplorerUrls: ["https://fieroscan.com"],
  },
];
