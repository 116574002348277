import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { RxCross2 } from "react-icons/rx";
import ButtonCircularProgress from "./ButtonCircularProgress";

const useStyles = makeStyles(() => ({
  IconBox: {
    position: "absolute",
    right: "0",
  },
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    "&::hover": {
      background: "transparent",
    },
    "& svg": {
      color: "#fff",
      fontWeight: "700",
      fontSize: "35px",
      background: "transparent",
    },
  },
  dialougTitle: {
    "& .subTextWallet": {
      color: "#FFFFFF99",
      maxWidth: "100%",
      marginTop: "20px",
    },
  },
}));
export default function ConfirmationDialogBox({
  openModal,
  handleClose,
  heading,
  description,
  HandleConfirm,
  isLoading,
  type,
}) {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        maxWidth="sm"
        fullWidth
        className={classes.dailogOpen}
        open={openModal}
        keepMounted
        onClose={() => !isLoading && handleClose()}
      >
        <DialogTitle className="dislogTitleBox">
          <IconButton
            disabled={isLoading}
            onClick={() => handleClose()}
            className="closeButton"
          >
            <RxCross2 />
          </IconButton>
        </DialogTitle>

        <DialogContent style={{ paddingBottom: "0px" }}>
          <Box className={classes.dialougTitle} align="center">
            <Typography className="ubuntu" variant="h4">
              {heading}
            </Typography>
            <Typography
              variant="body1"
              className="subTextWallet"
              style={{ fontFamily: "'Sora', sans-serif", color: "" }}
            >
              {description}
            </Typography>
          </Box>
        </DialogContent>
        {type !== "ended" && (
          <DialogActions
            style={{
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Box mt={2} mb={2} maxWidth="400px">
              <Button
                className="transparentbutton"
                style={{ minWidth: "133px" }}
                variant="contained"
                color="secondary"
                disabled={isLoading}
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
              {HandleConfirm && (
                <>
                  &nbsp;&nbsp;
                  <Button
                    variant="contained"
                    color="secondary"
                    // className="outlined"
                    style={{ minWidth: "133px" }}
                    onClick={HandleConfirm}
                    disabled={isLoading}
                  >
                    Confirm {isLoading && <ButtonCircularProgress />}
                  </Button>
                </>
              )}
            </Box>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
