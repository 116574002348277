import {
  Box,
  Drawer,
  Hidden,
  List,
  Button,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FaEuroSign, FaUserAlt } from "react-icons/fa";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PropTypes from "prop-types";
import NavItem from "./NavItem";
import { MdDashboard, MdSettings } from "react-icons/md";
import ConfirmationDialogBox from "src/component/ConfirmationDialogBox";
import { AuthContext } from "src/context/Auth";
import { SiMarketo } from "react-icons/si";
import { HiSpeakerphone } from "react-icons/hi";
import { FaUserGroup } from "react-icons/fa6";
import { BsBarChartFill, BsTools } from "react-icons/bs";

export const subAdmin = [
  {
    items: [
      {
        title: "Dashboard",
        icon: MdDashboard,
        href: "/dashboard",
      },
    ],
  },
];
export const settingAdmin = [
  {
    items: [
      {
        title: "Settings",
        icon: MdSettings,
        href: "/change-password",
      },
    ],
  },
];

export const sections = [
  {
    items: [
      {
        title: "Sub Admin Management",
        icon: FaUserGroup,
        href: "/sub-admin-management",
      },
      {
        title: "IP Management",
        // icon: HiUsers,
        img: "images/MenuIcon/subAdminLogo.svg",
        href: "/ip-management",
      },
      {
        title: "User Management",
        icon: FaUserAlt,
        href: "/usermanagemant",
      },
      {
        title: "NFT Management",
        icon: SiMarketo,
        href: "/nft-management",
      },
      {
        title: "Transaction Management",
        // icon: BiTransfer,
        img: "images/MenuIcon/transaction.svg",
        href: "/transaction-managemet",
      },
      {
        title: "Withdraw Management",
        icon: FaEuroSign,
        href: "/withdraw-management",
      },
      {
        title: "Event Management",
        // icon: MdEmojiEvents,
        img: "images/MenuIcon/event.svg",
        href: "/event-managemet",
      },
      {
        title: "Garage Management",
        icon: BsTools,
        // img: "images/MenuIcon/garage.svg",
        href: "/garage-managemet",
      },
      {
        title: "Announcement Management",
        icon: HiSpeakerphone,
        href: "/announcement-management",
      },
      {
        title: "Static Content Management",
        icon: BsBarChartFill,
        // img: "images/MenuIcon/staticContent.svg",

        href: "/static",
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, img, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        img={item.img}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        img={item.img}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
}
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: "#000000",
  },
  desktopDrawer: {
    top: "78px",
    width: "245px",
    height: "100% ",
    padding: "10px",
    // margin: "5px 10px 10px 15px",
    background: "#FFFFFF0F",
    boxShadow: "0px 0px 53px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    // marginTop: "0px",
    // marginLeft: "13px",
  },
  logoutButton: {
    left: "15px",
    bottom: "128px",
    display: "flex",
    position: "absolute",
    fontSize: "13px",
    background: "transparent",
    alignItems: "center",
    fontWeight: "400",
    color: "#FFFFFF99",
    justifyContent: "start",
    [theme.breakpoints.down("xs")]: {
      bottom: "-80px",
      left: "37px",
    },
  },
  sideMenuBox: {
    "& .MuiCollapse-wrapperInner": {
      marginLeft: "45px",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const auth = useContext(AuthContext);
  const history = useHistory();
  const [isLogout, setIsLogout] = useState(false);

  const checkArray = useMemo(() => {
    const matchedItems = sections.flatMap((section) =>
      section.items.filter(
        (item) =>
          auth?.adminUserData?.permission &&
          auth?.adminUserData?.permission.includes(item.title)
      )
    );
    return auth?.adminUserData?.userType != "ADMIN"
      ? [...subAdmin, { items: matchedItems }, ...settingAdmin]
      : [...subAdmin, ...sections, ...settingAdmin];
  }, [auth]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box pt={0.5} pb={2}>
          <Box className="sideMenuBox">
            {checkArray.map((section, i) => (
              <List
                key={`menu${i}`}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  img: section.img,
                  items: section.items,
                  pathname: location?.pathname,
                })}
              </List>
            ))}
          </Box>
        </Box>
        <Button
          onClick={() => setIsLogout(true)}
          className={classes.logoutButton}
        >
          <ExitToAppIcon
            style={{
              marginRight: "6px",
              color: "#FFFFFF99",
            }}
          />
          &nbsp; Logout
        </Button>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box p={2}>{content}</Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
      <ConfirmationDialogBox
        openModal={isLogout}
        handleClose={() => setIsLogout(false)}
        heading="Logout Admin"
        description={`Are you sure, you want to logout admin?`}
        HandleConfirm={() => {
          window.localStorage.removeItem("admin_token");
          auth.setAdminIsLogin(false);
          history.push("/");
        }}
        isLoading={false}
      />
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
