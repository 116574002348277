//////local/////
// export const baseurl = "http://172.16.6.130:1978"; //local URL
// export const socketBaseurl = "http://172.16.6.130:1978"; //local URL
// export const webSocketUrl = "ws://172.16.6.130:2100"; //local URL
// export const unitySandCardUrl = "http://172.16.11.253:2004"; //local URL

// ///Staging/////
export const baseurl = "https://nodeasad-icodashboard.mobiloitte.io"; //Staging URL
export const socketBaseurl = "https://nodeasad-icodashboard.mobiloitte.io"; //local URL
export const webSocketUrl = "wss://socket-icoasad.mobiloitte.io"; //local URL
export const unitySandCardUrl = "https://unity-asad.mobiloitte.io"; //local URL

/////Master/////
// export const baseurl = "https://node.rapidrush.io"; //Master URL
// export const socketBaseurl = "https://node.rapidrush.io"; //local URL
// export const webSocketUrl = "wss://socket-icoasad.mobiloitte.io"; //local URL
// export const unitySandCardUrl = "https://unity-asad.mobiloitte.io"; //local URL

let url = `${baseurl}/api/v1`;

const ApiConfig = {
  connectWallet: `${url}/user/connectWallet`,
  getProfileUser: `${url}/user/getProfile`,
  editProfile: `${url}/user/editProfile`,
  participatedEvents: `${url}/user/participatedEvents`,

  // //// //// admin-----------
  login: `${url}/admin/login`,
  forgotPassword: `${url}/admin/forgotPassword`,
  verifyOTP: `${url}/admin/verifyOTP`,
  resetPasswordAdmin: `${url}/admin/resetPasswordAdmin`,
  userList: `${url}/admin/userList`,
  userBlockUnblock: `${url}/admin/blockUnblockUser`,
  transactionManagement: `${url}/admin/mintingTransactionList`,
  changePassword: `${url}/admin/changePassword`,
  dashboard: `${url}/admin/dashboard`,
  profile: `${url}/admin/profile`,
  deleteUser: `${url}/admin/deleteUser`,
  updateUserProfile: `${url}/admin/updateUserProfile`,

  ///sub admin
  subAdminList: `${url}/admin/subAdminList`,
  createSubAdmin: `${url}/admin/createSubAdmin`,
  updateSubAdmin: `${url}/admin/updateSubAdmin`,
  blockUnblockSubAdmin: `${url}/admin/blockUnblockSubAdmin`,
  deleteSubAdmin: `${url}/admin/deleteSubAdmin`,
  listAllSubAdmin: `${url}/admin/listAllSubAdmin`,

  ///static content
  staticContent: `${url}/static/staticContent`,
  staticContentList: `${url}/static/staticContentList`,

  // event
  eventSchedule: `${url}/event/eventSchedule`,
  listEvent: `${url}/event/listEvent`,
  event: `${url}/event/event`,
  events: `${url}/event/event/`,
  joinEvent: `${url}/event/joinEvent`,
  enterEvent: `${url}/event/enterEvent`,
  statistics: `${url}/event/statistics`,

  // user event -----
  upcomingEvents: `${url}/user/upcomingEvents`,
  pastEvents: `${url}/user/pastEvents`,
  listCircuit: `${url}/circuit/listCircuit`,

  ///nft///
  createNFT: `${url}/nft/createNFT`,
  nftList: `${url}/nft/nftList`,
  mintedList: `${url}/nft/mintedList`,

  ///race//
  getRaceStatus: `${url}/circuit/getRaceStatus`,

  ///garage//
  listGarage: `${url}/garage/listGarage`,
  listItems: `${url}/garage/listItems`,
  listPurchasedGarages: `${url}/garage/listPurchasedGarages`,
  addGarage: `${url}/garage/addGarage`,
  blockUnBlockGarage: `${url}/garage/blockUnBlockGarage`,
  deleteGarage: `${url}/garage/deleteGarage`,
  specifications: `${url}/garage/specifications`,

  purchaseGarageListHistory: `${url}/user/purchaseGarageListHistory`,
  redeemGarage: `${url}/user/redeemGarage`,
  buyGarage: `${url}/user/buyGarage`,

  //circuit
  getLevelList: `${url}/circuit/getLevelList`,
  updateLevelList: `${url}/circuit/updateLevelList`,

  //announcement
  createAnouncement: `${url}/announcement/createAnouncement`,
  updateAnouncement: `${url}/announcement/updateAnouncement`,
  listAnouncemnt: `${url}/announcement/listAnouncemnt`,
  deleteAnouncement: `${url}/announcement/deleteAnouncement`,

  //IpManagement
  createIP: `${url}/ipManagement/createIP`,
  updateIP: `${url}/ipManagement/updateIP`,
  deleteIP: `${url}/ipManagement/deleteIP`,
  blockUnblockIP: `${url}/ipManagement/blockUnblockIP`,
  listIP: `${url}/ipManagement/listIP`,
  enableDisableIP: `${url}/ipManagement/enableDisableIP`,

  //auto event
  autoEventConfiguraton: `${url}/event/autoEventConfiguraton`,
  updateAutoEventConfiguraton: `${url}/event/updateAutoEventConfiguraton`,
  enableDisableAutoEvent: `${url}/event/enableDisableAutoEvent`,
};
export default ApiConfig;
