import axios from "axios";
import ApiConfig from "../ApiConfig";

export const dataPostHandler = async (endPoint, token, dataToSend) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      headers: {
        token: token || "",
      },
      data: dataToSend,
    });
    if (res.data.code === 200) {
      return res;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
    // toast.error(error?.response?.data?.message);
    return error.response;
  }
};
export const postAPIHandler = async ({
  endPoint,
  dataToSend,
  paramsData,
  token = window.localStorage.getItem("token"),
  source,
}) => {
  try {
    return await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      headers: {
        token: token || "",
      },
      data: dataToSend ? dataToSend : null,
      params: paramsData ? paramsData : null,
      cancelToken: source ? source.token : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const putAPIHandlerCall = async ({
  endPoint,
  dataToSend,
  paramsData,
  token = window.localStorage.getItem("token"),
}) => {
  try {
    return await axios({
      method: "PUT",
      url: ApiConfig[endPoint],
      headers: {
        token: token || "",
      },
      data: dataToSend ? dataToSend : null,
      params: paramsData ? paramsData : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getDataHandlerAPI = async (endPoint, token) => {
  try {
    return await axios({
      method: "GET",
      url: ApiConfig[endPoint],
      headers: {
        token: token || "",
      },
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const deleteParamsDataHandlerAPI = async ({
  endPoint,
  paramToSend,
  dataToSend,
  paramsData,
  token = window.localStorage.getItem("token"),
}) => {
  try {
    return await axios({
      method: "DELETE",
      url: paramToSend
        ? ApiConfig[endPoint] + paramToSend
        : ApiConfig[endPoint],
      headers: {
        token: token || "",
      },
      data: dataToSend ? dataToSend : null,
      params: paramsData ? paramsData : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const getParamsDataHandlerAPI = async (endPoint, token, paramToSend) => {
  try {
    return await axios({
      method: "GET",
      url: ApiConfig[endPoint] + paramToSend,
      headers: {
        token: token || "",
      },
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const getAPIHandler = async ({
  endPoint,
  id,
  paramsData,
  source,
  token = window.localStorage.getItem("token"),
}) => {
  try {
    return await axios({
      method: "GET",
      url: id ? `${ApiConfig[endPoint]}/${id}` : ApiConfig[endPoint],
      headers: {
        token: token || "",
      },
      params: paramsData ? paramsData : null,
      cancelToken: source ? source.token : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const getparamsAPIHandler = async ({
  endPoint,
  id,
  source,
  token = window.localStorage.getItem("token"),
}) => {
  try {
    return await axios({
      method: "GET",
      url: ApiConfig[endPoint],
      headers: {
        token: token || "",
      },
      params: id,
      cancelToken: source ? source.token : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const apiRouterCall = async ({
  method,
  endPoint,
  bodyData,
  paramsData,
  id,
  token,
}) => {
  try {
    const res = await axios({
      method: method,
      url: id ? `${ApiConfig[endPoint]}/${id}` : ApiConfig[endPoint],
      headers: {
        token: window?.localStorage?.getItem("admin_token") || "",
      },
      params: paramsData ? paramsData : null,
      data: bodyData ? bodyData : null,
    });
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
